import styled from "@emotion/styled"
import { Link } from 'gatsby';
import { Breakpoints } from '../../globalStyles';

const NavbarHeight = 300;
const NavmenuHeight = 60;
export const NavStickyPosition = NavbarHeight - NavmenuHeight;

export const NavmenuHeightMobile = '160px';

const blissfulColour = "#ee9fa0;"

export const LogoContainer = styled(Link)`
  height: ${NavStickyPosition}px;

  &.mobile-sticky-nav {
    transition: all 0.5s ease-in-out;
  }

  img {
    max-height: ${NavStickyPosition}px;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: ${Breakpoints.Mobile}) {
    top: 0;
    left: 0;
    height: ${NavmenuHeightMobile};
    img {
      height: ${NavmenuHeightMobile};
      max-height: ${NavmenuHeightMobile};
      display: flex;
      /* transition-delay: 0.2s; */
    }
    .desktop {
      display: none
    }
    .mobile {
      display: flex;
    }
  }
`

export const Nav = styled.div`
  height: ${NavbarHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
  background: rgba(255,255,255,0.975);
  backdrop-filter: blur(5px);

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(255,255,255,0.75);
    backdrop-filter: blur(5px);
  }
  /* transition: all 0.5s ease-in-out; */

  a {
    text-decoration: none;
  }

  &.sticky-nav {
    flex-direction: row;
    align-items: end;
    position: sticky;
    top: -${NavStickyPosition}px;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    padding-bottom: 0px;

    ${LogoContainer} {
      height: ${NavmenuHeight}px;
      transition: all 0.3s ease-in-out;
    }
    
    img {
      width: 58px;
      top: 0;
    }
  }

  &.mobile-sticky-nav {
    height: calc(${NavmenuHeightMobile} / 2);
    margin-bottom: ${NavmenuHeightMobile};
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    transition: all 0.5s ease-in-out;

    ${LogoContainer} {
      height: calc(${NavmenuHeightMobile} / 2);
      transform: translateX(calc(-50vw + 50px));
    }
    img {
      transition: all 0.5s ease-in-out;
      height: calc(${NavmenuHeightMobile} / 2);
      max-height: calc(${NavmenuHeightMobile} / 2);
    }
  }

  @media screen and (max-width: ${Breakpoints.Mobile}) {
    position: sticky;
    height: ${NavmenuHeightMobile};
    flex-direction: row;
  }
`;

export const Hamburger = styled.div`
  width: 40px;
  height: 4px;
  background: black;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;

  ::before, ::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 4px;
    background: black;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
  ::before {
    transform: translateY(-12px);
  }
  ::after {
    transform: translateY(12px);
  }

`

export const NavHamburger = styled.div`
  display: none;
  @media screen and (max-width: ${Breakpoints.Mobile}) {
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    .open {
      transform: translateX(25px);
      background: transparent;
    } 
    .open${Hamburger}::before  {
      transform: rotate(-45deg) translate(-15px, -15px);
    }
    .open${Hamburger}::after  {
      transform: rotate(45deg) translate(-15px, 15px);
    }
  }
`;

export const NavMenu = styled.ul`
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-align: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: ${NavmenuHeight}px;
  z-index: 1;

  &.sticky-nav {
    height: ${NavmenuHeight}px;
    border-bottom: none;
    margin-left: -58px;
  }

  @media screen and (max-width: ${Breakpoints.Mobile}) {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: calc(100vh - ${NavmenuHeightMobile});
    position: absolute;
    top: ${NavmenuHeightMobile};
    left: ${({ click }) => (click ? 0 : '-101%')};
    opacity:  ${({ click }) => (click ? 1 : 0)};
    transition: all 0.5s ease;
    background: white;

    &.mobile-sticky-nav {
      height: calc(100vh - calc(${NavmenuHeightMobile}/2));
      top: calc(${NavmenuHeightMobile}/2);
    }
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: ${Breakpoints.Mobile}) {
    height: auto;
    width: 100%;
    margin: 25px 0 0 0;
    padding: 0;
    font-size: clamp(20px, 4vh, 40px); 
    text-align: center;
  }
`;

export const NavLinks = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 10px;
  margin: 0px 2px;
  height: 100%;
  color: black;
  transition: all ease 0.4s;
  
  &:hover, &.active {
    background: ${blissfulColour};
    color: white;
  }

  @media screen and (max-width: ${Breakpoints.Mobile}) {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    height: auto;

    &:hover, &.active {
      color: ${blissfulColour};
      background: white;
      font-weight: 600;
    }

    &.mobile-sticky-nav {
    }
  }
`;
