import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pathname, ogImage, twitImage, ogImageDimensions, noIndex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const titleTemplate = `%s | ${site.siteMetadata.title}`;
  const metaDescription = description || site.siteMetadata.description
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const ogImageWidth = (ogImageDimensions && ogImageDimensions.width) || 1200;
  const ogImageHeight = (ogImageDimensions && ogImageDimensions.height) || 630;

  if (typeof window !== "undefined") {
    let hostOriginLink = window.location.origin;

    if (ogImage && !ogImage.includes('http')) {
      ogImage = ogImage ? `${hostOriginLink}${ogImage}` : undefined;
    }
    if (twitImage && !twitImage.includes('http')) {
      twitImage = twitImage ? `${hostOriginLink}${twitImage}` : undefined;
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(", "),
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          ogImage
            ? [
                {
                  property: "og:image",
                  content: ogImage,
                },
                {
                  property: "og:image:type",
                  content: "image/jpeg",
                },
                {
                  property: "og:image:width",
                  content: ogImageWidth,
                },
                {
                  property: "og:image:height",
                  content: ogImageHeight,
                },
                
              ]
            : []
        )
        .concat(
          twitImage
          ? [
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
            {
              name: "twitter:image",
              content: twitImage,
            },
          ]
          : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        )
        .concat(
          noIndex
          ?
          [
            {
              name: 'robots',
              content: 'noindex'
            }
          ]
          : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.arrayOf(PropTypes.object),
  pathname: PropTypes.string,
}

export default SEO