import styled from "@emotion/styled"
import { Link } from 'gatsby';

export const FooterContainer = styled.footer`
  flex-shrink: 0;
  padding-top: 30px;
  padding-bottom: 100px; 
  background-color: #ffebeb;
  text-align: center;
`

export const SocialIcons = styled.div`
  margin: 10px;
`

export const SocialIconLink = styled.a`
  text-decoration: none;
  background: black;
  color: white;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 3px 2px 1px 2px;
  margin: 5px;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.35s;

  &:hover {
    color: black;
    background: none;
  }
`
export const ContactInformation = styled.div`
  margin: 10px;
`
export const ContactEmail = styled.a`
  text-decoration: none;
  color: black;
  font-size: 17px;
`

export const WebsiteInfo = styled.p`
  margin: 20px;
  color: black;
  font-size: 14px;
`

export const FooterLink = styled(Link)`
  color: black;
  text-decoration: none;
`