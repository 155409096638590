import styled from "@emotion/styled"
import { Breakpoints } from '../../globalStyles';

export const ImageContainer = styled.div`
  margin: 5px;
  flex-basis: 100%;

  @media screen and (min-width: ${Breakpoints.Mobile}) {
    width: calc(33% - 10px);
    max-width: calc(33% - 15px);
    margin: 5px;
    height: 100%;
  }
  
`