import * as React from "react"
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Container, Breakpoints } from '../../globalStyles';
import { ImageContainer } from './GalleryImage.styles';

const GalleryImage = (props) => {
  return (
    <>
      <ImageContainer>
        <GatsbyImage
          image={props.image} 
          alt="Gallery image of a cake"
          placeholder="tracedSVG"
          quality={80}
          className="desktop"
        />
      </ImageContainer>
    </>
  )
}

export default GalleryImage

