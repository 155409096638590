import React, { useState, useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { useScrollPosition } from '../../hooks/useScrollPosition'
import { Link } from "gatsby"
import { Breakpoints } from '../../globalStyles';
import { BsBag } from '@react-icons/all-files/bs/BsBag'
import { Toast } from '../Toast/Toast';
import {
  NavStickyPosition,
  Nav, 
  LogoContainer,
  NavMenu,
  NavItem,
  NavLinks,
  NavHamburger,
  Hamburger
} from './Navbar.styles';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [scroll, setScroll] = useState(0);
  useScrollPosition(function setScrollPosition ({ currentPosition }) {
    setScroll(currentPosition.y);
  });

  let addStickyClassName;

  if (typeof window === 'undefined' || !window.document) {
    addStickyClassName = '';
  } else {
    if (innerWidth > parseInt(Breakpoints.Mobile)) {
      addStickyClassName = scroll < -NavStickyPosition ? 'sticky-nav' : '';
    } else {
      addStickyClassName = scroll < -50 ? 'mobile-sticky-nav' : '';
    }
  }

  const navLinks = [
    {
      "title": "Home",
      "link": "/"
    },
    {
      "title": "About",
      "link": "/about"
    },
    {
      "title": "Flavours",
      "link": "/flavours"
    },
    {
      "title": "Prices",
      "link": "/prices"
    },
    {
      "title": "Rentals",
      "link": "/rentals"
    },
    {
      "title": "Sample Box",
      "link": "/sample-box"
    },
    {
      "title": "Gallery",
      "link": "/gallery"
    },
    {
      "title": "FAQs",
      "link": "/faqs"
    },
    {
      "title": "Contact",
      "link": "/contact"
    }
  ]


  return (
    <> 
      <Nav className={addStickyClassName}>
 
        <LogoContainer to="/" className={addStickyClassName}>
          <StaticImage
            src="../../images/bc_gc_logo.png" 
            alt="Blissful Crumbs logo"
            placeholder="tracedSVG"
            className="desktop"
            height={240}
            quality={90}
            format="svg"
          />
          <StaticImage
            src="../../images/bc_gc_logo.png" 
            alt="Blissful Crumbs logo"
            placeholder="tracedSVG"
            className="mobile"
            height={160}
            quality={80}
            format="svg"
          />
        </LogoContainer>


        <NavHamburger onClick={handleClick} >
          <Hamburger className={click ? 'open' : ''} />
        </NavHamburger>

        <NavMenu 
          className={addStickyClassName}
          onClick={handleClick} click={click}
        >
          { navLinks.map((link) => {
            return (
              <NavItem key={link.link}>
                <NavLinks to={link.link} activeClassName="active" className={addStickyClassName}>
                  {link.title}
                </NavLinks>
              </NavItem>
            )
          })}


        </NavMenu>

        
      </Nav>
    </>
  )
}

export default Navbar

