import * as React from "react"
import { Global, css } from "@emotion/react"
import { 
  Navbar, 
  Footer,
  SEO
} from ".."
import '../../styles/main.css'

const Layout = (props) => (
  <>
    <Global
      styles={css`
        html, body {
          height: 100%;
          font-family: 'Raleway', sans-serif;
          line-height: 1.4;
        }
        body {
          display: flex;
          flex-direction: column;
          flex: 1 0 auto;
          margin: 0 auto;
          min-height: 100vh;
        }
        h1, h2, h3 {
          font-weight: 300;
        }
        h1 {
          font-size: 2.75rem;
        }
        h2 {
          font-size: 2rem;
        }
        h3 {
          font-size: 1.5rem;
        }
      `}
    />
    <SEO 
      title={props.title}
      description={props.description}
      pathname={props.pathname}
      ogImage={props.ogImage}
      twitImage={props.twitImage}
      ogImageDimensions={props.ogImageDimensions}
      noIndex={props.noIndex}
    />
    <Navbar />
    { props.children }
    <Footer />
  </>
)

export default Layout

