import styled from "@emotion/styled"

export const blissfulColour = "#ee9fa0;"
export const blissfulColourDark = "#e25b5d;"
export const blissfulColourLight = "#ffebeb";

export const Breakpoints = {
  Mobile: '960px'
}

export const Container = styled.div`
  margin: 0 10px;
  @media screen and (min-width: 1280px) {
    max-width: 1280px;
    margin: auto;
  }
`

export const PageTitleHeader = styled.h1`
  text-align: center;
  color: ${blissfulColour};
`

export const FormInputBlock = styled.div`
  width: 100%;
  margin: 15px 0;
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    /* width: 50%;
    display: inline-block; */
  }
`

export const FormInputParagraph = styled.p`
  /* margin-right: 10px; */
`

export const FormTextAreaBlock = styled.div`
  width: 95%;
`

export const FormLabel = styled.label`

`

export const FormInputText = styled.input`
  border: 0px solid lightgray;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  background-color: white;
  min-width: 300px;
  width: 95%;
  :disabled {
    background: lightgray;
    cursor: not-allowed;
  }
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    /* margin-right: 10px; */
    /* display: block; */
  }
`

export const FormSelect = styled.select`
  border: 1px solid lightgray;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  width: 100%;
  background-color: white;
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    width: 98%;
  }
`


export const InputFieldset = styled.fieldset`
  border: 1px solid lightgray;
  font-family: 'Raleway', sans-serif;
  margin: 0;
  width: 95%;
  :disabled {
    background: lightgray;
    color: gray;
  }
  label {
    margin-right: 20px;
  }

  input {
    /* border: 0px; */
  }
`

export const FormInputRadio = styled.input`
`

export const FormTextArea = styled.textarea`
  border: 1px solid lightgray;
  padding: 10px;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 16px;
`

export const FormButton = styled.button`
  padding: 15px 25px; 
  color: white;
  background-color: ${blissfulColour};
  border: none;
  text-align: center;
  border-radius: 15px;
  font-size: 18px;

  :hover {
    cursor: pointer; 
    filter: brightness(120%);
  }
`

export const RequiredAsterisk = styled.span`
  color: red;
  font-family: Arial, Helvetica, sans-serif;
`