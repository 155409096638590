import * as React from "react"
import { Container } from '../../globalStyles';
import { 
  FooterContainer, 
  SocialIcons, 
  SocialIconLink, 
  ContactInformation,
  ContactEmail,
  WebsiteInfo,
  FooterLink
} from './Footer.styles';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';

const Footer = () => (
  <>
    <FooterContainer>
      <Container>
        <SocialIcons>
          <SocialIconLink href="https://www.instagram.com/blissfulcrumbs/" target="_blank">
            <FaInstagram />
          </SocialIconLink>
          <SocialIconLink href="https://www.facebook.com/BlissfulCrumbsFB" target="_blank">
            <FaFacebookF />
          </SocialIconLink>
        </SocialIcons>
        <ContactInformation>
          <ContactEmail href="mailto:info@blissfulcrumbs.ca">
            info@blissfulcrumbs.ca
          </ContactEmail>
        </ContactInformation>
        <WebsiteInfo>
          © {new Date().getFullYear()} Blissful Crumbs. All Rights Reserved. | <FooterLink to="/privacy-policy"> Privacy Policy </FooterLink>
        </WebsiteInfo>
      </Container>
    </FooterContainer>
  </>
)

export default Footer

